import React, { useContext, Fragment } from 'react';
import { LinearProgress, Typography, Paper, TableRow, TableHead, TableContainer, TableBody, TableCell, Table, useMediaQuery, Button } from '@material-ui/core';

import { ContractsContext } from './../contexts/ContractsContext';
import './../css/Contracts.css';
// import contractsData from './data.json';
import nat from "./../assets/nat.jpg";

// import { jsPDF } from 'jspdf';

const Contracts = (props) => {
  const { contracts, isContractsLoaded } = useContext(ContractsContext);
  const mobileScreenQuery = useMediaQuery('(min-width:768px)');
  
  /* 
   * test data:
   * uncomment line under this.
   * comment VehicleForm form handleSubmit fetch call. 
   * pass true to Home Contracts isContractsLoaded useState().
   */
  // const contracts = contractData;
  // const printPDF = () => {
  //   const pdf = new jsPDF();
  //   pdf.text("Service Contract Quotes", pdf.internal.pageSize.width/2.9, 10);
  //   pdf.text("*Taxes not included. 10% down*", 1, 20);
  //   let yPos = 30;
  //   const pageHeight = pdf.internal.pageSize.height;
  //   for (let i = 0; i < contracts.length; i++) {
  //     const line = `${contracts[i].tier}, ${contracts[i].deductibleType}/${contracts[i].deductibleAmount}, ${contracts[i].coverageMonths}/${contracts[i].coverageMiles}, Price: $${contracts[i].price}, Monthly Payment: $${contracts[i].monthlyPayment}`
  //     pdf.text(line, 1, yPos);
  //     yPos += 10;
  //     if (yPos > pageHeight) {
  //       pdf.addPage();
  //       yPos = 20;
  //     }
  //   }
  //   pdf.save("TG Service Contracts Quotes.pdf");
  // }

  // const Header = () => {
  //   return (
  //     <div style={styles.titleContainer}>
  //       <Typography style={styles.title} variant="h3">Your available <br/> Service Plans</Typography>
  //       <br />
  //       <Typography style={styles.title} variant="h5">
  //         Don't forget to save your quotes!  
  //       </Typography>
  //       <Button color="secondary" variant="contained" size="large" onClick={printPDF}>Save as PDF</Button>
  //       <Typography style={styles.title} variant="h5">
  //         Call <a href="tel:3867934376">(386) 793 - 4376</a> to complete transaction!
  //       </Typography>
  //       <br />
  //       <Typography style={styles.disclaimerText} variant="h6">*Taxes not included. 10% down*</Typography>
  //     </div>
  //   )
  // }

  const ServicePlansTable = () => {
    return (
      <TableContainer id="capture" style={styles.paper(mobileScreenQuery)} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Plan Type</TableCell>
              <TableCell>Deductible Type/Price</TableCell>
              <TableCell>Coverage Month/Mile</TableCell>
              <TableCell>Plan Cost</TableCell>
              <TableCell>Monthly Payment</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {
            contracts.map((contract, k) => {
              return (
                <TableRow key={k}>
                  <TableCell>{contract.tier}</TableCell>
                  <TableCell>{contract.deductibleType}/{contract.deductibleAmount}</TableCell>
                  <TableCell>{contract.coverageMonths}/{contract.coverageMiles}</TableCell>
                  <TableCell>${contract.price}</TableCell>
                  <TableCell>${contract.monthlyPayment}</TableCell>
                </TableRow>
              )
            })
          }
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const RepPic = () => {
    return (
      <div>
        <LinearProgress style={{marginTop:"60px"}} color="secondary" size={100} /> 
        <img style={styles.dogImg} src={nat} alt="dog" />
        <br />
      </div>
    )
  }

  return (
    <div className="contractsContainer">
      <Fragment>
        <RepPic />
        <Typography style={{color: "white", paddingTop: "5%"}} variant="h5">
          Thank you for reaching out
        </Typography>
        <Typography style={{color: "white", margin: "10px"}} variant="h5">
          Our representative Natasha will be in touch! 
        </Typography>
      </Fragment>
    </div>
  );
}

const styles = {
  paper: query => ({
    display: 'block',
    marginLeft: "auto",
    marginRight: "auto",
    width: query ? '700px' : '',
  }),
  titleContainer: {
    marginTop: "70px",
    marginBottom: "10px",
  },
  title: {
    color: "white"
  },
  dogImg: {
    height: "200px",
    borderRadius: "50%",
    borderWidth: "thin",
    borderStyle: "solid",
    borderColor: "white",
    marginTop: "5%"
  },
  disclaimerText: {
    fontSize: "15px"
  }
}
 
export default Contracts;