import React, { Fragment } from 'react';
import { Typography, Grid } from '@material-ui/core';

import mail from './../assets/mail.png';
import pin from './../assets/pin.png';
import call from './../assets/call.png';
import nat from './../assets/nat.jpg';

const Contact = () => {
  return (
    <Fragment>
      <Grid style={styles.grid} container spacing={2}>
        <Grid container>
          <Grid item xs={12} align="center">
            <Typography style={styles.title} variant="h3">Need help?</Typography>
          </Grid>
        </Grid>
        <Grid container style={{padding: 10}}>
          <Grid item xs={12} align="center">
            <img style={styles.nat} src={nat} alt="tom gibbs rep"/>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} align="center" >
            <Typography style={styles.title} variant="h5">
              Find the best plan that works for you. <br/>
              Call or email Natasha Gibbs today! <br/>
              Or visit us at Tom Gibbs Chevrolet.
              </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{paddingTop: 20, paddingBottom: 10}}>
          <Grid item xs={4} align="right">
            <img style={styles.infoImg} src={call} alt="phone"/>
          </Grid>
          <Grid item xs={6}>
            <Typography style={styles.title} variant="h5">
            <a href="tel:3867934376">(386) 793 - 4376</a>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{padding: 10}}>
          <Grid item xs={4} align="right">
            <img style={styles.infoImg} src={mail} alt="email address"/>
          </Grid>
          <Grid item xs={6}>
            <Typography style={styles.title} variant="h5">natasha.tgchevy@gmail.com</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{padding: 10}}>
          <Grid item xs={4} align="right">
            <img style={styles.infoImg} src={pin} alt="in person address"/>
          </Grid>
          <Grid item xs={6}>
            <Typography style={styles.title} variant="h5">5850 FL-100, Palm Coast, FL 32164</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

const styles = {
  grid: {
    marginTop:"70px"
  },
  infoContainer: {
    display: "block"
  },
  nat: {
    height:"250px",
    marginLeft: "10px",
    borderRadius: "50%",
    borderWidth: "thin",
    borderStyle: "solid",
    borderColor: "white",
  },
  infoImg: {
    height:"50px",
    textAlign:"center"
  },
  container: {
    marginTop: "70px",
    display: "block",
    textAlign: "center",
  },
  title: {
    color: "white"
  },
}
export default Contact;