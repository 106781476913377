import React, { createContext, useState } from 'react';

export const ContractsContext = createContext();

const ContractsContextProvider = (props) => {
  const [ contracts, setContracts ] = useState([]);
  const [ vin, setVin ] = useState("");
  const [ mileage, setMileage ] = useState("");
  const [ isContractsLoaded, setIsContractsLoaded ] = useState(false);

  const initContracts = (data) => {
    setContracts(data);
  }

  const updateVin = (newVin) => {
    setVin(newVin);
  }

  const updateMileage = (newMileage) => {
    setMileage(newMileage)
  }

  const updateContractsLoaded = (isLoaded) => {
    setIsContractsLoaded(isLoaded);
  }

  return (
    <ContractsContext.Provider 
      value={{ 
        contracts, 
        vin, 
        mileage,
        isContractsLoaded,
        initContracts, 
        updateVin, 
        updateMileage,
        updateContractsLoaded}}
      >
      { props.children }
    </ContractsContext.Provider>
  );
}
 
export default ContractsContextProvider;