import React from 'react';
import { Grid, Typography, Paper, Link, useMediaQuery } from '@material-ui/core';

import engine from './../assets/engine.png';
import axle from './../assets/axle.png';
import transmission from './../assets/transmission.png';
import assembly from './../assets/assembly.png';
import gasket from './../assets/gasket.png';
import fuel from './../assets/fuel.png';
import suspension from './../assets/suspension.png';
import air from './../assets/air-conditioner.png';
import airbag from './../assets/airbag.png';
import steeringwheel from './../assets/steeringwheel.png';
import brake from './../assets/brake.png';
import cruise from './../assets/cruise.png';
import turbo from './../assets/turbo.png';

const Silver = (props) => {
  const mobileMediaQuery = useMediaQuery('(min-width:768px)');
  return (
    <Grid container>
      <Grid item xs={12} align="center">
        <Paper style={styles.paper(mobileMediaQuery)} elevation={3}>
          <Typography style={styles.paperTitle} variant="h4">
          Silver
          </Typography>
          <hr/>
          <Typography style={styles.paperText} variant="h6">
            The Silver protection plan covers specific parts and labor costs
            of components in need of repair. Unlike other aftermarket plans we've 
            got you covered, even if these parts are damaged by wear and tear.
          </Typography>
          <Typography style={styles.paperText} variant="h6">
            Check the 
            <Link style={{padding:"7px", cursor: "pointer"}} onClick={props.goToCompare}>
              Compare 
            </Link>
            tab to see information on Rentals, Towing, Deductible, and More!
          </Typography>
          <Typography style={styles.paperTitle} variant="h4">
            What Parts Are Covered?
          </Typography>
          <Grid container>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">Engine</Typography>
              <img src={engine} alt="engine" style={{height:"100px"}}/>
            </Grid>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">Transmission</Typography>
              <img src={transmission} alt="transmission" style={{height:"90px"}}/>
            </Grid>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">F/RWD Assembly</Typography>
              <img src={assembly} alt="assembly" style={{height:"90px"}}/>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">Turbo/Supercharger</Typography>
              <img src={turbo} alt="turbo" style={{height:"90px"}}/>
            </Grid>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">Drive Axle</Typography>
              <img src={axle} alt="axle" style={{height:"90px"}}/>
            </Grid>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">Cruise Control</Typography>
              <img src={cruise} alt="criuse control" style={{height:"90px"}}/>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">Fuel System</Typography>
              <img src={fuel} alt="fuel" style={{height:"95px"}}/>
            </Grid>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">Heating & A/C</Typography>
              <img src={air} alt="air conditioner" style={{height:"90px"}}/>
            </Grid>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">Seals & Gaskets</Typography>
              <img src={gasket} alt="gasket" style={{height:"100px"}}/>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">Safety Assemblies</Typography>
              <img src={airbag} alt="airbag" style={{height:"90px"}}/>
            </Grid>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">* Front Suspension</Typography>
              <img src={suspension} alt="suspension" style={{height:"90px"}}/>
            </Grid>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">Steering System</Typography>
              <img src={steeringwheel} alt="steeringwheel" style={{height:"90px"}}/>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={4} align="center">
              <Typography style={styles.paperText} variant="h6">* Brake System</Typography>
              <img src={brake} alt="brakes" style={{height:"90px"}}/>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

const styles = {
  paper: query => ({
    width: query ? '700px' : '',
    marginLeft: query ? '' : '10px',
    marginRight: query ? '' : '10px',
  }),
  grid: {
    marginTop:"70px",
  },
  title: {
    color: "white",
    marginTop: "15px",
  },
  paperText: {
    marginLeft:"20px",
    marginRight:"20px",
    marginBottom:"10px",
    textAlign: "center",
    fontWeight: "normal"
  },
  paperList: {
    marginLeft:"20px",
    marginRight:"20px",
    marginBottom:"10px",
    textAlign: "left",
    fontWeight: "normal"
  },
  paperTitle: {
    paddingTop:"10px",
    marginTop:"20px",
  }
}

export default Silver;