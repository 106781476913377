import React, { Fragment, useState } from 'react';
import { Grid, Typography, ButtonGroup, Button, } from '@material-ui/core';

import Platinum from './Platinum';
import Silver from './Silver';
import Compare from './Compare';

const Plans = () => {
  const [ show, setShow ] = useState('platinum');

  const goToCompare = () => {
    setShow('compare');
  }

  return (
    <Fragment>
      <Grid container style={styles.grid}>
        <Grid container>
          <Grid item xs={12} align="center">
            <Typography style={styles.title} variant="h3">Protection Plans</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid style={{marginTop: "15px"}} item xs={12} align="center">
            <ButtonGroup size="large" variant="contained" color="secondary" aria-label="contained button group">
              <Button onClick={() => setShow('platinum')}>Platinum</Button>
              <Button onClick={() => setShow('silver')}>Silver</Button>
              <Button onClick={() => setShow('compare')}>Compare</Button>
            </ButtonGroup>
          </Grid>
        </Grid>
        {show === 'platinum' && <Platinum goToCompare={goToCompare} />}
        {show === 'silver' && <Silver goToCompare={goToCompare} />}
        {show === 'compare' && <Compare />}
      </Grid>
    </Fragment>
  );
}

const styles = {
  grid: {
    marginTop:"70px",
  },
  title: {
    color: "white",
    marginTop: "15px",
  },
  paperText: {
    marginLeft:"20px",
    marginRight:"20px",
    marginBottom:"10px",
    textAlign: "center",
    fontWeight: "normal"
  },
  paperList: {
    marginLeft:"20px",
    marginRight:"20px",
    marginBottom:"10px",
    textAlign: "left",
    fontWeight: "normal"
  },
  paperTitle: {
    paddingTop:"10px",
    marginTop:"20px",
  }
}
 
export default Plans;