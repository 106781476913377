import React from 'react';
import { Typography, useMediaQuery } from '@material-ui/core';

const TitleMediaQuery = () => {
  const matches = useMediaQuery('(min-width:768px)')
  return (
    matches ?
      <Typography variant="body1" style={styles.text}>Tom Gibbs Chevrolet - Genuine GM Protection Plans </Typography> :
      <Typography variant="body2" style={styles.text}>Tom Gibbs Chevrolet - Genuine GM Protection Plans </Typography>
  )
}

const FooterBar = () => {
  return (
    <div>
      <div style={styles.sep} />
      <div style={styles.footer}>
        <div>
          <TitleMediaQuery />
          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/tomgibbschevrolet/"><button style={styles.button}>Facebook</button></a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.tomgibbschevy.com/"><button style={styles.button}>Main Site</button></a>
          <Typography variant="body2" style={styles.addressText}>5850 FL-100, Palm Coast, FL 32164</Typography>
        </div>
      </div>
    </div>
  );
}

const styles = {
  footer: {
    height: "100px",
    width: "100%",
    backgroundColor: "black",
    textAlign:"center"
  },
  sep: {
    height: "1rem",
  },
  text: {
    color: "white",
    paddingTop:"10px"
  },
  addressText: {
    color: "white",
  },
  button: {
    color: "white",
    backgroundColor: "#3ec4ff",
    borderRadius: "3px",
    margin: "0.5rem",
    height: "1.7rem",
    border: "none",
    cursor: "pointer",
  }
}
 
export default FooterBar;