import React, { Fragment } from 'react';
import Home from './components/Home';

function App() {
  document.body.style.backgroundColor = "#40baf0";
  return (
    <Fragment>
      <Home />
    </Fragment>
  );
}

export default App;
