import React from 'react';
import { Grid, Typography, Paper, Link, useMediaQuery } from '@material-ui/core';

import engine from './../assets/engine.png';
import transmission from './../assets/transmission.png';
import assembly from './../assets/assembly.png';
import gasket from './../assets/gasket.png';
import fuel from './../assets/fuel.png';
import suspension from './../assets/suspension.png';
import air from './../assets/air-conditioner.png';
import rad from './../assets/rad.png';
import airbag from './../assets/airbag.png';
import steeringwheel from './../assets/steeringwheel.png';
import chipandsound from './../assets/chipandsound.png';
import brake from './../assets/brake.png';
import axle from './../assets/axle.png';
import cruise from './../assets/cruise.png';
import turbo from './../assets/turbo.png';

const Platinum = (props) => {
  const mobileMediaQuery = useMediaQuery('(min-width:768px)');

  return (
    <Grid container>
      <Grid item xs={12} align="center">
        <Paper style={styles.paper(mobileMediaQuery)} elevation={3}>
        <Typography style={styles.paperTitle} variant="h4">
          Platinum
        </Typography>
        <hr/>
        <Typography style={styles.paperText} variant="h6">
          The Platinum protection plan covers your vehicle unlike any other
          extended warranty service! This includes all electrical or mechanical
          components, even if they are damaged due to normal wear and tear. 
        </Typography>
        <Typography style={styles.paperText} variant="h6">
          Check the 
          <Link style={{padding:"7px", cursor: "pointer"}} onClick={props.goToCompare}>
            Compare 
          </Link>
          tab to see information on Rentals, Towing, Deductible, and More!
        </Typography>
        <Typography style={styles.paperTitle} variant="h4">
          What Parts Are Covered?
        </Typography>
        <Grid container>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">Engine</Typography>
            <img src={engine} alt="engine" style={{height:"100px"}}/>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">Transmission</Typography>
            <img src={transmission} alt="transmission" style={{height:"90px"}}/>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">F/RWD Assembly</Typography>
            <img src={assembly} alt="assembly" style={{height:"90px"}}/>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">Turbo/Supercharger</Typography>
            <img src={turbo} alt="turbo" style={{height:"90px"}}/>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">Drive Axle</Typography>
            <img src={axle} alt="axle" style={{height:"90px"}}/>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">Cruise Control</Typography>
            <img src={cruise} alt="criuse control" style={{height:"90px"}}/>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">Fuel System</Typography>
            <img src={fuel} alt="fuel" style={{height:"95px"}}/>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">Engine Cooling</Typography>
            <img src={rad} alt="radiator" style={{height:"90px"}}/>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">Heating & A/C</Typography>
            <img src={air} alt="air conditioner" style={{height:"90px"}}/>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">Seals & Gaskets</Typography>
            <img src={gasket} alt="gasket" style={{height:"100px"}}/>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">Safety Assemblies</Typography>
            <img src={airbag} alt="airbag" style={{height:"90px"}}/>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">F/R Suspension</Typography>
            <img src={suspension} alt="suspension" style={{height:"90px"}}/>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">Steering System</Typography>
            <img src={steeringwheel} alt="steeringwheel" style={{height:"90px"}}/>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">Electronics & Audio</Typography>
            <img src={chipandsound} alt="chipandsound" style={{height:"95px"}}/>
          </Grid>
          <Grid item xs={4} align="center">
            <Typography style={styles.paperText} variant="h6">* Brake System</Typography>
            <img src={brake} alt="brakes" style={{height:"90px"}}/>
          </Grid>
        </Grid>
        <Typography style={styles.paperTitle} variant="h4">
          Theres More!
        </Typography>
        <Typography style={styles.paperText} variant="h6">
          The Platinum plan goes above and beyond so you can have true peace of mind! 
        </Typography>
        <Typography style={styles.paperText} variant="h6">
          It also includes the following:
        </Typography>
        <Grid container>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Navigation Compass
            </Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Ride Control Suspension
            </Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Heated Seats
            </Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Blind-spot sensors
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Display Screen
            </Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Reverse System/Sensor
            </Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Keyless Entry System
            </Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Rear Camera/Sensor
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Factory Entertainment System
            </Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Factory Hands-Free Accessories
            </Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Factory <br/> Anti-Theft System
            </Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Automatic Climate Programmer
            </Typography>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Emergency Trunk Release
            </Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • Power Tailgate Lock
            </Typography>
          </Grid>
          <Grid item xs={3} align="center">
            <Typography style={styles.paperList} variant="h6">
            • ...And More!
            </Typography>
          </Grid>
        </Grid>

        <Typography style={styles.paperText} variant="body1">
          * Brake Pads, Rotors, and Drums not covered.
          </Typography>
        <br />
        </Paper>
      </Grid>
    </Grid>
  )
}

const styles = {
  paper: query => ({
    width: query ? '700px' : '',
    marginLeft: query ? '' : '10px',
    marginRight: query ? '' : '10px',
  }),
  grid: {
    marginTop:"70px",
  },
  title: {
    color: "white",
    marginTop: "15px",
  },
  paperText: {
    marginLeft:"20px",
    marginRight:"20px",
    marginBottom:"10px",
    textAlign: "center",
    fontWeight: "normal"
  },
  paperList: {
    marginLeft:"20px",
    marginRight:"20px",
    marginBottom:"10px",
    textAlign: "left",
    fontWeight: "normal"
  },
  paperTitle: {
    paddingTop:"10px",
    marginTop:"20px",
  }
}

export default Platinum;