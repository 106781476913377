import React, { Fragment } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import VehicleForm from './VehicleForm';
import LeadInfo from './LeadInfo';
import Contracts from './Contracts';
import Navbar from './Navbar';
import FooterBar from './FooterBar';
import ContractsContextProvider from './../contexts/ContractsContext';
import About from './About';
import Contact from './Contact';
import Plans from './Plans';

import { ThemeProvider } from '@material-ui/core/styles';
import theme from './../theme';
import './../css/Home.css';


const Home = () => {
  const Routes = () => (
    <Fragment>
      <ContractsContextProvider>
        <Route exact path='/' component={VehicleForm} />
        <Route path='/leadform' component={LeadInfo} />
        <Route path='/contracts' component={Contracts} />
        <Route path='/about' component={About} />
        <Route path='/contact' component={Contact} />
        <Route path='/plans' component={Plans} />
      </ContractsContextProvider>
    </Fragment>
  );

  return (
    <Fragment>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
            <Navbar />
            <div style={styles.content}>
              <Routes />
            </div>
        </ThemeProvider>
      </BrowserRouter>
      <FooterBar />
      {/* debug */}
      {/* <button onClick={openPop}> click! </button> */}
    </Fragment>
  );
}

const styles = {
  footerSeperation: {
    height: "1rem",
  },
  popper: {
    float: "left",
  },
  content: {
    minHeight: "calc(100vh - 100px)"
  }
}

export default Home;