import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3ec4ff",
      light: "#202424"
    },
    secondary: {
      main: "#ffd54f"
    },
  },
  shape: {
    borderRadius: 5
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
      }
    }
  }
});

export default theme;