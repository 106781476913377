import React, { Fragment } from 'react';
import { Typography, Grid, Link, useMediaQuery } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import tgOutside from './../assets/tg1.jpg';
import oliver from './../assets/oliver.jpg';

const About = (props) => {
  const goToPlans = (e) => {
    e.preventDefault();

    props.history.push("/plans");
  }

  const AboutMediaQuery = () => {
    const matches = useMediaQuery('(min-width:768px)');
    return (
      matches ? 
      <Grid container style={styles.grid}>
        <Grid container>
          <Grid item xs={12} align="center">
            <Typography style={styles.title} variant="h3">Service Plans</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} align="center">
            <Typography style={styles.text} variant="h5">We want to provide the best 
            plan for you! That's why we offer Genuine GM Protection Plans for you
            to choose from to have access to the most wide variety of coverages.
            Check out our 
            <Link style={{padding:"7px", cursor: "pointer"}} onClick={goToPlans}>
              Plan Types 
            </Link>
            page to learn more!</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} align="center">
            <img style={styles.oliver} src={oliver} alt="dog" />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} align="center">
            <Typography style={styles.title} variant="h3">Tom Gibbs Chevrolet</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} align="center">
            <Typography style={styles.text} variant="h5">Tom Gibbs Chevrolet is a 
              family-owned and operated new and used car dealership that has served 
              Flagler Beach and Palm Coast for over 35 years.  Being a full service 
              dealership with car department, service department, parts department, 
              and collision center, Tom Gibbs Chevrolet is your one-stop-shop for 
              all your automotive needs.
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} align="center">
            <img style={styles.tgOutsideImg} src={tgOutside} alt="tom gibbs outside" />
          </Grid>
        </Grid>
      </Grid> 
      :
      <Grid container style={styles.grid}>
      <Grid container>
        <Grid item xs={12} align="center">
          <Typography style={styles.title} variant="h4">Service Plans</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} align="center">
          <Typography style={styles.text} variant="h6">We want to provide the best 
          plan for you! That's why we offer Genuine GM Protection Plans for you
          to choose from to have access to the most wide variety of coverages.
          Check out our 
          <Link style={{padding:"7px", cursor: "pointer"}} onClick={goToPlans}>
            Plan Types 
          </Link>
          page to learn more!</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} align="center">
          <img style={styles.oliverMobile} src={oliver} alt="dog" />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} align="center">
          <Typography style={styles.title} variant="h4">Tom Gibbs Chevrolet</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} align="center">
          <Typography style={styles.text} variant="h6">Tom Gibbs Chevrolet is a 
            family-owned and operated new and used car dealership that has served 
            Flagler Beach and Palm Coast for over 35 years.  Being a full service 
            dealership with car department, service department, parts department, 
            and collision center, Tom Gibbs Chevrolet is your one-stop-shop for 
            all your automotive needs.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} align="center">
          <img style={styles.tgOutsideImgMobile} src={tgOutside} alt="tom gibbs outside" />
        </Grid>
      </Grid>
    </Grid>
    );
  }

  return (
    <Fragment>
      <AboutMediaQuery />
    </Fragment>
  );
}

const styles = {
  tgOutsideImg: {
    height:"340px",
    borderRadius: "10px",
    borderWidth: "thin",
    borderStyle: "solid",
    borderColor: "white"
  },
  tgOutsideImgMobile: {
    height:"240px",
    borderRadius: "10px",
    borderWidth: "thin",
    borderStyle: "solid",
    borderColor: "white"
  },
  oliver: {
    height:"320px",
    borderRadius: "10px",
    borderWidth: "thin",
    borderStyle: "solid",
    borderColor: "white"
  },
  oliverMobile: {
    height:"220px",
    borderRadius: "10px",
    borderWidth: "thin",
    borderStyle: "solid",
    borderColor: "white"
  },
  grid: {
    marginTop:"50px",
  },
  title: {
    marginTop: "20px",
    textAlign: "center",
    color: "white"
  },
  text: {
    color:"white",
    textAlign: "justify",
    maxWidth: "800px",
    paddingBottom: "20px",
    margin: "20px"
  }
}
 
export default withRouter(About);