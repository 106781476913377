import React, { Fragment, useState, useRef } from 'react';
import { AppBar, Toolbar, Button, IconButton, useMediaQuery, MenuItem, Popper, Grow, ClickAwayListener, MenuList, Paper, Typography, Popover } from '@material-ui/core';
import { Link } from "react-scroll";
import MenuButton from "@material-ui/icons/Menu";
import { withRouter } from 'react-router-dom';

import cross from './../assets/cross.png';
import TomGibbsLogo from './../assets/tgc_2020logo.png';
import './../css/Navbar.css';

const Navbar = (props) => {
  const AppBarMediaQuery = () => {
    const matches = useMediaQuery('(min-width:895px)');
    const [ menuOpen, setMenuOpen ] = useState(false);
    const [ popoverEl, setPopoverEl ] = React.useState(null);
    const menuAnchor = useRef(null);
    const open = Boolean(popoverEl);
    const id = open ? 'help-popover' : undefined;

    const handleMenuToggle = () => {
      setMenuOpen(!menuOpen);
    }

    const handleMenuClose = (event, nameOfRoute) => {
      if (menuAnchor.current && menuAnchor.current.contains(event.target)) {
        return;
      }
  
      setMenuOpen(false);

      if(nameOfRoute != null) {
        goToRoute(nameOfRoute);
      }
    }


    const goToRoute = (name) => {
      props.history.push("/" + name);
    }

    const handlePopoverOpen = (e) => {
      setPopoverEl(e.currentTarget)
    }

    const handlePopoverClose = () => {
      setPopoverEl(null);
    }

    return (
      matches ?
      <Fragment>
        <img id="tgLogo" style={styles.logo} src={TomGibbsLogo} alt="Tom Gibbs Logo" onClick={() => goToRoute("")} />
        <Button style={styles.assistanceButton} variant="contained" color="primary" onClick={handlePopoverOpen}>
          Get Assitance Now!
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={popoverEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography style={{float: "left", paddingTop: "10px", paddingLeft: "5px"}}>
            Need help? Call: <a href="tel:386-793-4376" style={{paddingLeft:"2px", paddingRight:"3px"}}>386 793-4376</a>
            <br />
            Check the Contact page for more!
          </Typography>
          <img className="cross" onClick={handlePopoverClose} src={cross} alt="close help" style={{cursor: "pointer", float: "right",margin:"6px",height: "25px"}} />
        </Popover>
        <div className="buttonGroup">
          <Button onClick={() => goToRoute("plans")} style={styles.buttons}>Plan Types</Button>
          <Button onClick={() => goToRoute("about")} style={styles.buttons}>About</Button>
          <Button onClick={() => goToRoute("contact")} style={styles.buttons}>Contact</Button>
          <Link activeClass="active" to="quoteTitle" smooth={true} duration={1500}>
            <Button onClick={() => goToRoute("")} variant="contained" color="secondary" style={styles.buttons}>Get Quote!</Button>
          </Link>  
        </div> 
      </Fragment>
      :
      <Fragment>
        <img id="tgLogo" src={TomGibbsLogo} alt="Tom Gibbs Logo" className="tomgibbsLogo" height={55} onClick={() => goToRoute("")} />
        <div className="buttonGroup">
          <IconButton ref={menuAnchor} onClick={handleMenuToggle}>
            <MenuButton />
          </IconButton>
          <Popper open={menuOpen} anchorEl={menuAnchor.current} role={undefined} transition disablePortal>
            {
              ({ TransitionProps, placement }) => (
                <Grow 
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleMenuClose}>
                      <MenuList autoFocusItem={menuOpen}>
                        <MenuItem onClick={e => handleMenuClose(e, "plans")}>Plan Types</MenuItem>
                        <MenuItem onClick={e => handleMenuClose(e, "about")}>About</MenuItem>
                        <MenuItem onClick={e => handleMenuClose(e, "contact")}>Contact</MenuItem>
                        <MenuItem onClick={e => handleMenuClose(e, "")}>Get Quote</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )
            }
          </Popper>
        </div>
      </Fragment>
    )
  }

  return (
    <AppBar id="mainNavbar" elevation={3} color="inherit" position="fixed" style={styles.bar}>
      <Toolbar>
        <AppBarMediaQuery />
      </Toolbar>
    </AppBar>
  );
}

const styles = {
  logo: {
    height: "55px",
    cursor: "pointer",
    paddingBottom: ".1rem",
    marginLeft: "-10px",
    marginRright: "5px",
  },
  buttons: {
    fontSize: '1rem',
    fontWeight: 400,
    margin: "3px"
  },
  assistanceButton: {
    fontSize: '1rem',
    fontWeight: 400,
    marginLeft: "20px"
  },
  appbar: {
    margin: 0,
    padding: 0,
    border: "none",
    outline: "none",
  },
  bar: {
    height: "60px",
  }
};
 
export default withRouter(Navbar);