import React from 'react';
import { Grid, Typography, Paper, Table, TableContainer, TableBody, TableHead, TableRow, TableCell, useMediaQuery } from '@material-ui/core';

import check from './../assets/check.png';
import xmark from './../assets/x.png';

const Compare = () => {
  const mobileMediaQuery = useMediaQuery('(min-width:768px)');
  return (
    <Grid container>
      <Grid item xs={12} align="center">
        <Paper style={styles.paper(mobileMediaQuery)} elevation={3}>
          <Typography style={styles.paperTitle} variant="h4">
          Compare
          </Typography>
          <hr/>
          <Typography style={styles.paperText} variant="h6">
            The Protection Plans come with services to help put your mind at ease
            when things don't go as planned!
          </Typography>
          <Typography style={styles.paperTitle} variant="h4">
            Rental
          </Typography>
          <Typography style={styles.paperText} variant="h6">
            The plan will pay for or reimburse you for a rental vehicle or for any 
            public transportation expenses while your vehicle is being repaired, 
            up to $40 per day (max. $280/visit). 
          </Typography>
          <Typography style={styles.paperTitle} variant="h4">
            Towing & Road Service
          </Typography>
          <Typography style={styles.paperText} variant="h6">
            In the event that your vehicle is disabled, the plan will
            cover your towing or road service charges up to $150
            per occurrence.  
          </Typography>
          <Typography style={styles.paperTitle} variant="h4">
            Trip Interruption
          </Typography>
          <Typography style={styles.paperText} variant="h6">
            If a covered breakdown occurs more than 100 miles from
            your home and a repair facility needs to keep it overnight,
            the plan will reimburse you up to $200 a day (max. 4 days)
            for hotel and restaurant expenses. 
          </Typography>
          <Typography style={styles.paperTitle} variant="h4">
            Lost Key & Lock Out
          </Typography>
          <Typography style={styles.paperText} variant="h6">
            Keys are easy to lose. If your keys are lost or broken, or if
            you accidentally lock yourself out of your vehicle, the plan
            will reimburse you up to $35 for locksmith services.  
          </Typography>
          <Typography style={styles.paperTitle} variant="h4">
            Transferable
          </Typography>
          <Typography style={styles.paperText} variant="h6">
            If you decide to sell your vehicle, your coverage can easily
            be transferred to the new owner upon payment of any
            applicable fee and completion of paperwork.   
          </Typography>
          <Typography style={styles.paperTitle} variant="h4">
            One-time Deductible Eligibility
          </Typography>
          <Typography style={styles.paperText} variant="h6">
            You can choose the deductible that is right for you. Once
            a part is repaired or replaced, the deductible for future
            repair or replacement of the same part is waived. 
          </Typography>

          <Typography style={styles.paperTitle} variant="h4">
            Which Plan is right for you? <span role="img" aria-label="monocle emoji">🧐</span>
          </Typography>
          <br />
          <TableContainer style={styles.table(mobileMediaQuery)} component={Paper}>
            <Table size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Coverage</TableCell>
                  <TableCell align="right">Silver</TableCell>
                  <TableCell align="right">Platinum</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Engine</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Transmission</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Drive Axle</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Factory-Installed Turbocharger/Supercharger</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Transfer Case</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Steering (including rear-wheel steering)</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Electrical</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Enhanced Electrical – OnStar, MyLink (programming and updates not included)</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Air Bags/Safety Restraint System</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Brakes (including ABS components)</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Air Conditioning System</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Seals and Gaskets (of covered components)</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Adaptive Cruise Control</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Compass</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ride Control Suspension</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Heated Seats</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Adaptive Cruise Control</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Blind-Spot Sensors</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Video Display Screen (excluding pixel damage)</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Reverse Warning Systems/Sensors</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Keyless Entry System (excluding key/key fob)</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Rear Vision Camera and Sensors</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Factory-Installed Entertainment System</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Emergency Trunk Release</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Power Tailgate Lock</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Automatic Climate Control Programmer</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Factory-Installed, Hands-Free Voice-Activated Accessories</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Factory-Installed Anti-Theft Systems
                  (does not include transmitters and receivers)
                  </TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>And More!</TableCell>
                  <TableCell align="right"><img style={styles.marker} src={xmark} alt="x-mark" /></TableCell>
                  <TableCell align="right"><img style={styles.marker} src={check} alt="checkmark" /></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <br />
        </Paper>
      </Grid>
    </Grid>
  )
}

const styles = {
  paper: query => ({
    width: query ? '700px' : '',
    marginLeft: query ? '' : '10px',
    marginRight: query ? '' : '10px',
  }),
  marker: {
    height:"20px"
  },
  table: query => ({
    width: query ? "600px" : '',
  }),
  grid: {
    marginTop:"70px",
  },
  title: {
    color: "white",
    marginTop: "15px",
  },
  paperText: {
    marginLeft:"20px",
    marginRight:"20px",
    marginBottom:"10px",
    textAlign: "center",
    fontWeight: "normal"
  },
  paperList: {
    marginLeft:"20px",
    marginRight:"20px",
    marginBottom:"10px",
    textAlign: "left",
    fontWeight: "normal"
  },
  paperTitle: {
    paddingTop:"10px",
    marginTop:"20px",
  }
}

export default Compare;