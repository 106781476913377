import React, { useState, useContext, Fragment } from 'react';

import { withRouter } from 'react-router-dom';

import ReactPlayer from 'react-player';

import Typist from 'react-typist';
import { Button, TextField, Typography, Paper, useMediaQuery } from '@material-ui/core';

import { ContractsContext } from './../contexts/ContractsContext';
import ChevyFamily from './../assets/chevyfam.jpg';
import './../css/VehicleInfo.css';

const server_url = process.env.REACT_APP_SERVER_URL;
const NatVid = "https://www.youtube.com/embed/dA09na7c7jY";

function useForceUpdate(){
  // eslint-disable-next-line
  const [value, setValue] = useState(0);
  return () => setValue(value => ++value);
}

class TypedWords extends React.PureComponent {
  render() {
    return (
      <Typography style={styles.imgText} variant="h4">
        <Typist cursor={{hideWhenDone:true, hideWhenDoneDelay:0}}>
            The best plan, for you.
          <Typist.Backspace count={4} delay={1000} />
            the family.
          <Typist.Backspace count={11} delay={1000} />
            everyone.
        </Typist>
      </Typography>
    )
  }
}


class HeaderImage extends React.PureComponent {
  render() {
    return (
      <div style={styles.imgContainer}>
        <img style={styles.headerImg} src={ChevyFamily} alt="Chevy Family" className="chevymoutains"/>
        <div style={styles.imgTextContainer}>
          <TypedWords />
        </div>
      </div>
    )
  }
}

const VideoScreenQuery = () => {
  const matches = useMediaQuery('(min-width:639px)');
  return (
    matches ? 
    <div style={styles.videoBorder}>
      <ReactPlayer style={styles.video} url={NatVid} width='100%' height='100%' controls={true} playing={true} muted={true}/>
    </div>
    :
    <div>
      <ReactPlayer url={NatVid} width='100%' height='100%' controls={true} playing={true} muted={true}/>
    </div>
  )
}

const VehicleForm = (props) => {
  const [ VIN, setVIN ] = useState("");
  const [ mileage, setMileage ] = useState("");
  // eslint-disable-next-line
  const [ vinError, setVinError] = useState({error: false, text: ""});
  // eslint-disable-next-line
  const [ mileageError, setMileageError] = useState({error: false, text: ""});
  const forceUpdate = useForceUpdate();
  const NavBarQuery = useMediaQuery('(min-width:768px)');
  const MobileQuery = useMediaQuery('(min-width:639px)');

  const { 
    initContracts, 
    updateVin, 
    updateMileage,
    updateContractsLoaded,
  } = useContext(ContractsContext);

  const handleSumbitQuote = (e) => {
    e.preventDefault();
    // maybe we call the validates/update in the onchange
    validateVIN();
    validateMileage();
    forceUpdate();

    // form validation check
    if (vinError.error || mileageError.error) {
      return;
    }
    
    // update Context
    updateVin(VIN);
    updateMileage(mileage);

    const data = { VIN, mileage };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }

    const vehicleFormUrl = server_url + "/vehicle_form";
    // fetch(vehicleFormUrl, options)
    //   .then(resp => resp.json())
    //   .then(json => {
    //     initContracts(json);
    //     updateContractsLoaded(true);
    //   });
    props.history.push('/leadform')
  }

  const validateVIN = () => {
    // TODO: alphanumeric check
    if (VIN.length !== 17) {
      vinError.error = true;
      vinError.text = `VIN must be a 17 characters long. You have entered ${VIN.length}.`;
    } else {
      vinError.error = false;
      vinError.text = "";
    }
  }

  const validateMileage = () => {
    if (isNaN(mileage)) {
      mileageError.error = true;
      mileageError.text = "Mileage must be a number.";
    } else {
      mileageError.error = false;
      mileageError.text = "";
    }
  }

  return (
    <Fragment>
      <HeaderImage />
      <Typography id="quoteTitle" style={styles.title(NavBarQuery)}>
        Get a Free Quote!
      </Typography>
      <Typography style={styles.titleDesc(NavBarQuery)}>
        Check out our Genuine GM Protection Plans
      </Typography>

      <VideoScreenQuery />

      <Paper style={styles.paper(MobileQuery)} elevation={3} id="vehicleForm">
        <Typography style={styles.formTitle} variant="h5">Vehicle Information</Typography>
        <form onSubmit={handleSumbitQuote}>
          <TextField 
            style={styles.textField}
            required
            error={vinError.error} 
            helperText={vinError.text} 
            id="outlined-basic" 
            label="VIN"
            variant="outlined" 
            fullWidth={true}
            onChange={e => setVIN(e.target.value)} />
          <TextField 
            style={styles.textField} 
            required 
            error={mileageError.error} 
            helperText={mileageError.text} 
            id="outlined-basic" 
            type="tel" 
            label="Mileage" 
            variant="outlined" 
            fullWidth={true}
            onChange={e => setMileage(e.target.value)} />
          <Button fullWidth={true} style={styles.button} type="submit" variant="contained" size="large">Next</Button>
        </form>
      </Paper>
    </Fragment>
  );
}

const styles = {
  videoBorder: {
    borderRadius: "5px",
    display: "block",
    margin: "0 auto",
    position: "relative",
    height: "340px",
    width: "640px",
    border: "1px solid white",
    overflow: "hidden"
  },
  video: {
    display: "absolute",
    margin: "0 auto",
  },
  headerImg: {
    width: "100%",
    marginTop: "60px",
  },
  imgContainer: {
    textAlign: "center",
    position: "relative",
    // marginTop: "2rem"
  },
  imgTextContainer: {
    position: "absolute",
    top: "6%",
    left: "2%",
    color: "white",
  },
  imgText: {
    fontSize: "5vw",
    marginTop: "60px"
  },
  formContainer: {
    // backgroundColor: "#3ec4ff",
    // width: "100%",
    // height: "100%"
  },
  title: query => ({
    textAlign: "center",
    color: "white",
    fontSize: query ? "36px" : "24px",
    fontWeight: "500"
  }),
  titleDesc: query => ({
    textAlign: "center",
    color: "white",
    fontSize: query ? "24px" : "18px",
    fontWeight: "500"
  }),
  textField: {
    width: "90%",
    margin: ".5rem",
  },
  paper: query => ({
    width: query ? "640px": "",
    textAlign: "center",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "0.5rem"
    // backgroundColor: "#90A4AD"
  }),
  formTitle: {
    paddingTop: "0.5rem"
    // color: "white"
  },
  button: {
    marginBottom: "1rem",
    width: "90%",
    height: "4rem",
    color: "white",
    fontSize: "1.4rem",
    backgroundColor: "#3ec4ff"
  }
}

export default withRouter(VehicleForm);