import React, { useState, useContext, Fragment } from 'react';
import { TextField, Button, Typography, Paper, useMediaQuery } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { ContractsContext } from './../contexts/ContractsContext';

import './../css/LeadInfo.css';

const server_url = process.env.REACT_APP_SERVER_URL;

function validateEmail(email) {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

function useForceUpdate() {
  // eslint-disable-next-line
  const [value, setValue] = useState(0);
  return () => setValue(value => ++value);
}

const LeadInfo = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // eslint-disable-next-line
  const [emailError, setEmailError] = useState({ error: false, text: "" });
  const MobileQuery = useMediaQuery('(min-width:639px)');

  const { vin, mileage } = useContext(ContractsContext);
  const forceUpdate = useForceUpdate();

  const handleSubmitLead = (e) => {
    e.preventDefault()

    validateEmailAddr();
    forceUpdate();

    if (emailError.error) {
      return;
    }

    const data = { firstName, lastName, emailAddress, vin, mileage, phoneNumber };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    }

    const sendLeadUrl = server_url + "/send_lead";
    fetch(sendLeadUrl, options)
      .then(resp => resp.json());

    window.scrollTo({ top: 0, behavior: 'smooth' });
    props.history.push('/contracts')
  }

  const validateEmailAddr = () => {
    if (!validateEmail(emailAddress)) {
      emailError.error = true;
      emailError.text = "Please enter a valid email";
    } else {
      emailError.error = false;
      emailError.text = "";
    }
  }

  const HeaderMediaQuery = () => {
    const matches = useMediaQuery('(min-width:768px');
    return (
      matches ?
        <Fragment>
          <Typography style={styles.titleTop} variant="h3">Last step, please fill out the form below.</Typography>
        </Fragment>
        :
        <Fragment>
          <Typography style={styles.titleTopMobile} variant="h5">Last step, please fill out the form below.</Typography>
        </Fragment>
    )
  }

  return (
    <Fragment>
      <div className="leadInfoContainer">
        <HeaderMediaQuery />
        <Paper style={styles.paper(MobileQuery)}>
          <Typography style={styles.formTitle} variant="h5">Customer Information</Typography>
          <form className="leadForm" onSubmit={handleSubmitLead}>
            <TextField required style={styles.textField} id="outlined-basic" label="First Name" variant="outlined" fullWidth={true} onChange={e => setFirstName(e.target.value)} />
            <TextField required style={styles.textField} id="outlined-basic" label="Last Name" variant="outlined" fullWidth={true} onChange={e => setLastName(e.target.value)} />
            <TextField
              required
              error={emailError.error}
              helperText={emailError.text}
              style={styles.textField}
              id="outlined-basic"
              label="Email Address"
              variant="outlined"
              fullWidth={true}
              onChange={e => setEmailAddress(e.target.value)}
            />
            <TextField style={styles.textField} id="outlined-basic" label="Phone (Optional)" variant="outlined" fullWidth={true} onChange={e => setPhoneNumber(e.target.value)} />
            <Button style={styles.button} type="submit" variant="contained" color="primary" size="large">Send</Button>
          </form>
        </Paper>
      </div>
    </Fragment>
  );
}

const styles = {
  paper: query => ({
    width: query ? "640px" : "",
    textAlign: "center",
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "0.5rem"
    // backgroundColor: "#90A4AD"
  }),
  textField: {
    width: "90%",
    margin: ".5rem",
  },
  button: {
    marginBottom: "1rem",
    width: "90%",
    height: "4rem",
    color: "white",
    fontSize: "1.4rem",
    backgroundColor: "#3ec4ff"
  },
  titleTop: {
    marginTop: "100px",
    marginBottom: "10px",
    textAlign: "center",
    color: "white"
  },
  titleTopMobile: {
    marginTop: "100px",
    marginBottom: "10px",
    textAlign: "center",
    color: "white",
    fontSize: "29px"
  },
  title: {
    marginTop: ".5rem",
    marginBottom: "10px",
    textAlign: "center",
    color: "white"
  },
  dogImg: {
    height: "200px",
    borderRadius: "50%",
    borderWidth: "thin",
    borderStyle: "solid",
    borderColor: "white"
  },
  dogImgMobile: {
    height: "150px",
    borderRadius: "50%",
    borderWidth: "thin",
    borderStyle: "solid",
    borderColor: "white"
  },
  formTitle: {
    paddingTop: "0.5rem"
  }
}

export default withRouter(LeadInfo);